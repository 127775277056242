import "./Style.css";

const faqs = [
    { question: "1. Vad är detta för sajt?", answer: "Det är en oberoende sajt, ämnad för padelspelare i Ale-Kungälv, som syftar till att visa aktuella rankingstegar och statistik, samt matcherna de baseras på. Kontakta padel@itissjodahl.se för mer information och för att lägga till dig som spelare." },
    { question: "2. Vad är rating?", answer: "Rating är ett sätt att jämföra spelnivå mellan spelare. Efter varje match läggs det till eller dras ifrån ratingpoäng, enligt det s.k. Elo Rating System. Vinst mot motståndare med högre rating ger fler ratingpoäng än vinst mot motståndare med lägre rating. En förlust mot ett lag med lägre rating innebär att fler poäng dras av, jämfört med en förlust mot ett lag som har högre ratingpoäng. Alla typer av matcher ger ratingpoäng, fast med olika viktning. Sanktionerade matcher ger 2.0 * ratingpoängen, klubbtävlingsmatcher ger 1.5 * ratingpoängen samt vänskapsmatcher ger 1.0 * ratingpoängen." },
    { question: "3. Hur tilldelas en initial rating?", answer: "Varje ny spelare tilldelas en rating på mellan 1400 och 1600. Poängen baseras i nuläget på tre faktorer; officiella rankingpoäng (viktning 0.5), deltagande i seriespel i Ale-Kungälv (viktning 0.2) samt inofficiell ranking (viktning 0.3) som sätts av admin. Ratingen justeras sedan automatiskt och blir mer tillförlitlig ju fler matcher som rapporteras. Skulle man som spelare hamna lite snett från start med ratingen, justeras detta snabbt när matcherna väl spelas." },
    { question: "4. Får man fler ratingpoäng man vinner matchen med 3-0?", answer: "Nej, endast vinst eller förlust avgör fördelningen av ratingpoäng." },
    { question: "5. Hur fungerar lagrating?", answer: "Lag har ingen egen rating utan bygger enbart på spelarnas individuella rating. Ratingen blir medelvärdet mellan dessa två. Förändras ratingen för dessa två så ändras också lagets rating." },
];

export default function FAQPage() {
    return (
        <div className="faq-container">
            <h2>Frågor & svar</h2>
            <div className="faq-card">
                {faqs.map((faq, index) => (
                    <div key={index} className="faq-item mb-4 p-3 border-b border-gray-300">
                        <h3>{faq.question}</h3>
                        <p>{faq.answer}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}