import React, { useEffect, useState } from "react";
import axios from "axios";
import './Style.css';

const Ladder = () => {
    const [ladder, setLadder] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filterGender, setFilterGender] = useState("M"); // Filter state
    const [searchTerm, setSearchTerm] = useState(""); // Search input state
    const [filteredLadder, setFilteredLadder] = useState([]); // Filtered ladder for search
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';

    useEffect(() => {
        const fetchLadder = async () => {
            try {
                const token = localStorage.getItem("token");
                const response = await axios.get(`${API_BASE_URL}/ladder`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                        params: { gender: filterGender, t: Date.now() }, // Pass gender filter as query parameter  
                });
                console.log("Ladder data fetched:", response.data); // Debug log
                setLadder(response.data); // Store ladder data
                setFilteredLadder(response.data); // Initialize filtered ladder with full ladder
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    console.error("Unauthorized: Token may be invalid or expired.");
                    localStorage.removeItem("token");
                    window.location.href = "/login";
                } else {
                    console.error("Error fetching ladder data:", error);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchLadder();
    }, [filterGender, API_BASE_URL]); // Re-fetch when filterGender changes

    // Handle search input change
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    // Handle search on pressing Enter
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            if (searchTerm.trim() === "") {
                setFilteredLadder(ladder); // Reset to full ladder if search term is empty
            } else {
                const searchResults = ladder.filter((player) =>
                    player.PlayerName.toLowerCase().includes(searchTerm.toLowerCase())
                );
                setFilteredLadder(searchResults);
            }
        }
    };

    // Clear search input
    const clearSearch = () => {
        setSearchTerm(""); // Clear the input
        setFilteredLadder(ladder); // Reset filtered ladder to full ladder
    };

    // Function to render movement as an icon
    const renderMovement = (movement) => {
        switch (movement) {
            case "Up":
                return <svg class="icon icon--up" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M12 4l-8 8h5v8h6v-8h5z"></path>
                </svg>; // Green up arrow
            case "Down":
                return <svg class="icon icon--down" viewBox = "0 0 24 24" aria-hidden="true" >
                    <path d="M12 20l8-8h-5v-8h-6v8h-5z"></path>
                </svg>; // Red down arrow
            case "Same":
                return <svg class="icon icon--hyphen" viewBox = "0 0 24 24" aria-hidden="true" >
                    <path d="M6 11h12v2H6z"></path>
                </svg>; // Black hyphen
            default:
                return null;
        }
    };

    return (
        <div>
            <h2>Individuell stege</h2>

            
            <div style={{ margin: "0.2rem", display: "flex", alignItems: "center", gap: "0.7rem" }}>
                <label>
                    {/* Gender Filter Toggle */}
                    <input
                        type="checkbox"
                        checked={filterGender === "F"}
                        onChange={(e) => setFilterGender(e.target.checked ? "F" : "M")}
                        style={{ flex: "1", padding: "0.5rem", fontSize: "0.8rem" }}
                    />
                    Visa damspelare
                </label>
           
                {/* Search Input and Clear Button */}
                    <input
                        type="text"
                        placeholder="Sök spelare + ENTER"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        onKeyDown={handleKeyDown} // Trigger search on Enter
                        style={{ flex: "1", padding: "0.1rem", width: "8.5rem" }}
                    />
                    <button
                        onClick={clearSearch}
                        style={{
                            padding: "0.1rem",
                            fontSize: "0.8rem",
                            cursor: "pointer",
                            border: "2px solid #ccc",
                            borderRadius: "3px",
                            backgroundColor: "#FFFFFF",
                            flex: "1",
                            color: "black", // Set font color to black
                            width: "2rem",
                        }}
                    >
                        X
                    </button>
                </div>     
            
            
            {loading ? (
                <p>Loading...</p>
            ) : (
                <table border="1" style={{ width: "100%", textAlign: "center" }}>
                    <thead>
                        <tr>
                            <th>Ranking</th>
                            <th>Spelare</th>
                            <th>Rating</th>
                            <th>Matcher</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredLadder.map((player, index) => (
                            <tr key={index}>
                                <td>
                                    <div class="cell-content">
                                        <span class="number">{player.CurrentRank}</span> {renderMovement(player.Movement)}
                                    </div>
                                </td>
                                <td>{player.PlayerName}</td>
                                <td>{player.CurrentRating}</td>
                                <td>{player.MatchesPlayed}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default Ladder;

