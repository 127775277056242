import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import About from "./About.js";
import './Style.css';

const HamburgerMenu = ({ isAuthenticated, handleLogout }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [matchesDropdownVisible, setMatchesDropdownVisible] = useState(false);
    const [laddersDropdownVisible, setLaddersDropdownVisible] = useState(false);
    const [showAbout, setShowAbout] = useState(false);
    const navigate = useNavigate();
    const userRole = localStorage.getItem("userRole"); // Retrieve user role

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const closeDropdowns = () => {
        setMatchesDropdownVisible(false);
        setLaddersDropdownVisible(false);
        setShowAbout(false);
    };

    const toggleAboutPopup = () => {
        setShowAbout(!showAbout);
        closeMenu(); // Close the hamburger menu
    };

    const handleLogoutClick = () => {
        handleLogout();
        closeDropdowns();
        closeMenu(); // Close the hamburger menu
        navigate("/"); // Redirect to home after logout
    };

    return (
        <nav className="hamburger-menu">
            {/* Hamburger Icon */}
            <div className={`hamburger-icon ${isMenuOpen ? "open" : ""}`} onClick={toggleMenu}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>

            {/* Navigation Menu */}
            {isMenuOpen && (
                <div className="menu">
                    {/* Ladders Dropdown */}
                    <div
                        className="dropdown"
                        onMouseEnter={() => setLaddersDropdownVisible(true)}
                        onMouseLeave={() => setLaddersDropdownVisible(false)}
                    >
                        <span className="menu-item">Stegar</span>
                        {laddersDropdownVisible && (
                            <div className="dropdown-content">
                                <Link to="/" className="dropdown-link" onClick={closeMenu}>
                                    Individuell stege
                                </Link>
                                <Link to="/team-ladder" className="dropdown-link" onClick={closeMenu}>
                                    Lagstege
                                </Link>
                            </div>
                        )}
                    </div>

                    {/* Matches Dropdown */}
                    <div
                        className="dropdown"
                        onMouseEnter={() => setMatchesDropdownVisible(true)}
                        onMouseLeave={() => setMatchesDropdownVisible(false)}
                    >
                        <span className="menu-item">Matcher</span>
                        {matchesDropdownVisible && (
                            <div className="dropdown-content">
                                {isAuthenticated ? (
                                    <Link to="/register-match" className="dropdown-link" onClick={closeMenu}>
                                        Registrera match
                                    </Link>
                                ) : (
                                    <span className="dropdown-link disabled">Registrera match</span>
                                )}
                                <Link to="/match-history" className="dropdown-link" onClick={closeMenu}>
                                    Matchhistorik
                                </Link>
                            </div>
                        )}
                    </div>

                    {/* Stats Link */}
                    <Link to="/stats" className="menu-item" onClick={closeMenu}>
                        Statistik
                    </Link>

                    {/* InfoFAQ Link */}
                    <Link to="/FAQ" className="menu-item" onClick={closeMenu}>
                        Info & FAQ
                    </Link>

                    {/* Authentication Links */}
                    <div className="auth-links">
                        {isAuthenticated && userRole === "admin" && (
                            <Link to="/admin/add-player" onClick={closeMenu}>
                                Admin</Link>
                        )}
                        {!isAuthenticated ? (
                            <Link to="/login" className="menu-item2" onClick={closeMenu}>
                                Logga in
                            </Link>
                        ) : (
                                <Link to="#" className="menu-item2" onClick={handleLogoutClick}>
                                Logga ut
                            </Link>
                        )}
                    </div>
                </div>
            )}
        </nav>
    );
};

export default HamburgerMenu;
