import React, { useEffect, useState } from "react";
import axios from "axios";
import './Style.css';
import { useParams, useNavigate } from "react-router-dom";

const MatchHistory = ({ isAuthenticated, userID }) => {
    const [matches, setMatches] = useState([]);
    const [allMatches, setAllMatches] = useState([]);
    const [showUserMatches, setShowUserMatches] = useState(false); // State for checkbox
    const [loading, setLoading] = useState(true);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';
    const navigate = useNavigate();

    useEffect(() => {
        const fetchMatchHistory = async () => {
            try {
                const token = localStorage.getItem("token");
                const response = await axios.get(`${API_BASE_URL}/match-history`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        showUserMatches: showUserMatches, // Pass checkbox state to backend
                    },
                });
                console.log("Fetched match history:", response.data);
                setMatches(response.data);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    console.error("Unauthorized: Token may be invalid or expired.");
                    localStorage.removeItem("token");
                    window.location.href = "/login";
                } else {
                    console.error("Error fetching match history:", error);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchMatchHistory();
    }, [showUserMatches]); // Refetch data when showUserMatches changes

    const toggleUserMatches = () => {
        setShowUserMatches(!showUserMatches);
    };

    return (
        <div>
            <h2>Matchhistorik</h2>
            {isAuthenticated && (
                <div>
                    <label>
                        <input
                            type="checkbox"
                            checked={showUserMatches}
                            onChange={toggleUserMatches}
                        />
                        Visa mina matcher
                    </label>
                </div>
            )}
            {loading ? (
                <p>Loading...</p>
            ) : matches.length > 0 ? (
                <table border="1" style={{ width: "100%", textAlign: "center" }}>
                    <thead>
                        <tr>
                            <th>Datum</th>
                            <th>Lag A</th>
                            <th>Resultat</th>
                            <th>Lag B</th>
                        </tr>
                    </thead>
                        <tbody>
                            {matches.map((match) => {
                                const resultStyle = {
                                    color: match.Result === "Win" ? "green" : "red",
                                    fontWeight: "bold",
                                };
                                const translatedResult =
                                    match.Result === "Win" ? "VINST" : "FÖRLUST";

                                return (
                                    <tr key={match.MatchID || `${match.TeamAID}-${match.TeamBID}`}>
                                        <td>{match.MatchDate
                                            ? new Intl.DateTimeFormat('en-CA').format(new Date(match.MatchDate))
                                            : "N/A"}</td>
                                        <td>{match.TeamAPlayers}</td>
                                        <td style={resultStyle}>{translatedResult}</td>
                                        <td>{match.TeamBPlayers}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                </table>
            ) : (
                <p>No match history available.</p>
            )}
        </div>
    );
};

export default MatchHistory;
